// IMPORTS
@import (reference) "settings.less";

// PRODUCTS (mainpage & detail)
.main-page-products-wrapper,
#detail-tabs {

    .product {
        position: relative;
        .grid();
        grid-template-rows: auto auto 1fr;
        overflow: hidden;
        padding-bottom: 32px;
        border-bottom: 1px dashed @sec-color;
    }

    .product-image {
        aspect-ratio: 1 / 1;
        position: relative;
        transition: transform .1s ease-in-out;
        margin-bottom: 16px;
    }

    a.product-name {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        --fontSize: 18px;
        height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        font-size: 18px;
        color: @header-color;
        z-index: 1; // IMPORTANT
        /*font-family: @sec-font-family;*/

        &:after {
            content: '';
            position: absolute;
            inset: 0;
        }

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .product-desc {
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        height: ~"calc(3 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
    }

    .product-price {
        margin-top: 20px;
        align-self: end;
        display: flex;
        align-items: flex-end;
        gap: 16px;

        b, del {

            &:after {
                content: ' zł';
            }
        }

        b {
            font-size: 24px;
            color: @header-color;
        }

        del {
            color: rgba(32, 33, 36, 0.7);
            position: relative;
            top: -4px;
        }
    }

    @media (min-width: 1280px) {
        .product:hover {

            a.product-name {
                text-decoration: underline;
            }
        }
    }
}

// PRODUCT BADGES
.product-badges {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    text-align: center;

    span {
        background-color: @header-color;
        padding: 4px 8px;

        &.promo-badge {
            background-color: @error-color;
            order: -1;
        }
    }
}

// NEWSLETTER
#newsletter {
    position: relative;
    color: @header-color;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: calc(0.5 * var(--rowGap));

    &.unchecked .newsletter-clauses-wrapper:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        color: @error-color;
        margin-top: 10px;
    }
}

.newsletter-bg {
    .center-lr();
    top: 0;
    width: 101vw;
    height: 100%;
    z-index: 0;
    background-color: @main-color;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.newsletter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 56px;
    background: @main-color;
    border-radius: 16px;
    max-width: 704px;
    z-index: 1;
}

.newsletter-header {
    margin-bottom: 12px;

    b {
        font-size: 32px;
        line-height: 1.2;
        font-family: @sec-font-family;
        font-style: italic;
        display: block;
        margin-bottom: 16px;
        font-weight: 800;
        text-shadow:
            3px 3px 0px #fff,
            -3px 3px 0px #fff,
            -3px -3px 0px #fff,
            3px -3px 0px #fff,
            -3px 0px 3px white,
            3px 0px 3px white,
            0px -4px 0px white,
            0px 2px 0 white;
    }
}

.newsletter-input {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;

    input[type="text"] {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid @header-color;
        border-radius: 0;
        color: @header-color;
        font-size: 16px;
        height: 56px;
        font-family: @main-font-family;
        padding-left: 0;

        &:focus::placeholder {
            color: transparent;
        }
        &::-webkit-input-placeholder {
            color: @header-color;
        }
        &::-moz-placeholder {
            color: @header-color;
        }
        &:-ms-input-placeholder {
            color: @header-color;
        }
        &:-moz-placeholder {
            color: @header-color;
        }
    }

    .popup-trigger {
        margin-top: 16px;
        .confirm-button();
        height: auto;
    }
}

.newsletter-clauses-wrapper.unchecked {
    padding-bottom: 10px;

    &:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        clear: both;
        color: @error-color;
    }
}

// FOOTER
#footer {
    position: relative;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -2;
        background-color: @sec-color;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #603214;
    padding: var(--rowGap) 0;
    gap: 16px;
    color: @footer-gray;
}

.footer-item {

    > strong {
        font-size: 18px;
        margin-bottom: 24px;
        display: block;
        color: @footer-gray;
    }

    &.submenu {

        ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: -8px;
        }

        a {
            display: inline-block;
            padding: 8px 0;
            color: @footer-gray;

            &:hover {
                color: black;
            }
        }
    }
}

.footer-logo {
    margin-top: 46px;
    align-self: flex-start;
    width: 148px;
    aspect-ratio: ~"148/150";

    img {
        .img-contain();
    }
}

#footer-poll {
    flex-basis: 18.5%;

    > b {
        display: block;
        margin-bottom: 20px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: -8px;

        li {
            padding: 8px 0;
            display: flex;
            gap: 16px;
            cursor: pointer;

            &[data-poll-vote-id] {
                align-items: center;
                position: relative;

                &:before {
                    content: '';
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    border: 1px solid @footer-gray;
                }

                &:after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: 0 5px;
                    background-color: transparent;
                    transition: background-color 0.05s ease-in-out;
                }

                &:hover {

                    &:after {
                        background-color: @footer-gray;
                    }
                }
            }

            &:not([data-poll-vote-id]) {
                flex-direction: column;
                gap: 3px;
                cursor: default;

                div {
                    position: relative;
                    padding: 4px 10px;
                    color: @header-color;

                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        background-color: @main-color;
                        z-index: -1;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}

.contact-wrapper {
    columns: 2;
    column-gap: 84px;

    strong {
        grid-column: 1 / 3;
    }

    > div {
        max-width: 213px;
        break-inside: avoid-column;
    }

    .location,
    .hours {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        position: relative;
        color: @footer-gray;

        b {
            margin-bottom: 4px;
        }

        &:before {
            .small-icon();
            position: absolute;
            left: 0;
            top: 0;
            color: @footer-gray;
        }
    }

    .location {
        margin-bottom: 20px;

        b:before {
            content: 'Zakład produkcyjny:';
        }

        &:before {
            content: "\e914";
        }
    }

    .hours:before {
        content: "\e913";
    }

    .phone-mail {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 24px;

        a {
            padding: 8px 0;
            color: @footer-gray;
            display: flex;
            align-items: center;
            position: relative;
            gap: 10px;

            &:first-child {
                padding-top: 0 !important;
            }

            &:before {
                .small-icon();
            }

            &:hover {
                color: black;
            }
        }

        .phone:before {
            content: "\e91c";
        }

        .email:before {
            content: "\e91b";
        }
    }

    .footer-social-links {
        display: flex;
        gap: 10px;

        a {
            &:before {
                .small-icon();
                color: @footer-gray;
                font-size: 24px;
            }

            &:hover:before {
                color: black;
            }
        }

        .facebook-link:before {
            content: "\e91a";
        }

        .instagram-link:before {
            content: "\e919";
        }
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 32px 0 56px;

    a {
        color: @footer-gray;

        &:hover {
            color: black;
        }
    }

    > span {
        color: @footer-gray;
    }
}